<template>
  <div ref="main" class="m-main-wrap" v-loading="loadList">
    <div ref="topDom"></div>
    <!-- 内部侧边栏 -->
    <el-container>
      <el-main>
        <!-- 表格 -->
        <el-table
          :data="tableData.data"
          style="width: 100%"
          :height="tableHeight"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column width="60" label="NO.">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column label="廣告位" width="200">
            <template slot-scope="scope">{{ scope.row.title }}</template>
          </el-table-column>
          <el-table-column prop="categoryFormat" label="廣告代碼" width="500">
            <template slot-scope="scope">
              {{scope.row.content}}
              <!-- <el-input
                placeholder="请输入内容"
                disabled
                type="textarea"
                class="c-copy-con"
                v-model="scope.row.content"
              ></el-input>
              <el-link
                class="ml10"
                type="primary"
                @click="copyUrl(scope.row.content)"
                >复制</el-link
              > -->
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <el-link type="primary" @click="openEditDialog(scope.$index)"
                >編輯</el-link
              >
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页 ｜ 批量移动-->
        <div ref="btmDom" class="flex-bt flex-mid pb10 pt10">
          <el-pagination
            background
            hide-on-single-page
            :page-size="tableData.pageSize"
            :current-page="tableData.pageNum"
            :total="tableData.total"
            @current-change="changePage"
          ></el-pagination>
        </div>
      </el-main>
    </el-container>
    <createDialog
      :show="showCreateDialog"
      @close="close"
      :type="type"
      :item="curItem"
      @confirm="editData"
    ></createDialog>
  </div>
</template>
<script>
import { calTableHeight } from "@/common/tool"
import createDialog from "./components/createGoogleAdsDialog.vue"
export default {
  components: { createDialog },
  data() {
    return {
      loadList: false,
      showCreateDialog: false,
      curItem: {},
      type: "create",
      curIndex: -1,
      tableData: {
        data: [],
        pageNum: 1,
        pageSize: 15,
        total: 0,
      },
      tableHeight: 100, //表格高度
    }
  },
  created() {
    this.getDataList()
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = calTableHeight(this)
    })
  },
  methods: {
    // copyUrl(str) {
    //   if (window.clipboardData) {
    //     return window.clipboardData.setData("text", str)
    //   }
    //   var save = function(e) {
    //     e.clipboardData.setData("text/plain", str)
    //     e.preventDefault()
    //   }
    //   document.addEventListener("copy", save)
    //   // document.getElementById(id).select()
    //   document.execCommand('copy', false, null)
    //   document.removeEventListener("copy", save)
    //   this.$message.success("复制成功")
    // },
    async getDataList(page = 1) {
      this.loadList = true
      let res = await this.api.ads.getGoogleAds(page)
      if (res && res.code == 0) {
        this.tableData = res.data
      }
      this.loadList = false
    },
    changePage(pageNum) {
      this.getDataList(pageNum)
    },
    close() {
      this.showCreateDialog = false
    },
    openEditDialog(index) {
      this.type = "edit"
      this.curIndex = index
      this.curItem = this.tableData.data[index]
      this.showCreateDialog = true
    },
    async editData(data) {
      let res = await this.api.ads.editGoogleAds({ ...data })
      if (res && res.code == 0) {
        this.$message({
          message: "更新成功",
          type: "success",
        })
        this.showCreateDialog = false
        this.getDataList(this.tableData.current_page)
      }
    },
  },
}
</script>
<style scoped>
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fff;
  border-color: #e4e7ed;
  color: #555;
  cursor: not-allowed;
  resize: none;
  height: auto;
}
</style>
